var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ws-dialog',{attrs:{"title":_vm.$t('file_browser.share_file.title'),"width":"700","max-height":"600px","height":"90%","not-centered":"","no-padding":""},scopedSlots:_vm._u([{key:"header-after",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('ws-navigation-header',{attrs:{"items":_vm.navigationSelect,"no-number":"","padding":"24px"},on:{"input":function($event){_vm.selectedUsers = []}},model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}}),_c('div',{staticStyle:{"width":"100%"},style:(("border-bottom: 4px solid " + _vm.wsBACKGROUND))}),_c('div',{style:(("border-bottom: 4px solid " + _vm.wsBACKGROUND))},[_c('ws-text-field',{staticClass:"pr-2",attrs:{"placeholder":_vm.$t('Search'),"append-icon":"mdi-magnify","solo":"","flat":"","clearable":"","width":"200"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"default",fn:function(){return [(_vm.navigation === 'shared_users')?_c('div',{staticClass:"fill-height"},[(_vm.items.length > 0)?_c('ws-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search,"row-action":function ($event) { return _vm.selectUser($event.uuid); },"footer-portal":"storage_file_access_footer"},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"min-width":"24","width":"24","height":"24","color":_vm.wsACCENT,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectUser(item.uuid)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selectedUsers.includes(item.uuid) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ws-user-data',{attrs:{"item":item}})]}}],null,false,2078938905)}):_vm._e(),(_vm.items.length === 0)?_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"size":"80","color":_vm.wsACCENT}},[_vm._v(" mdi-account-tie ")])],1),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.not_shared')))]),_c('h4',{staticClass:"text-center font-weight-regular"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.not_shared_text')))]),_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('ws-button',{attrs:{"label":"file_browser.share_file.choose_users"},on:{"click":function($event){_vm.navigation='choose_users'}}})],1)])]):_vm._e()],1):_vm._e(),(_vm.navigation === 'choose_managers')?_c('div',{staticClass:"fill-height"},[(_vm.managersSelectFiltered.length > 0)?_c('ws-data-table',{attrs:{"items":_vm.managersSelectFiltered,"headers":_vm.managersHeaders,"search":_vm.search,"row-action":function ($event) { return _vm.selectUser($event.uuid); },"footer-portal":"storage_file_access_footer"},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"min-width":"24","width":"24","height":"24","color":_vm.wsACCENT,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectUser(item.uuid)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selectedUsers.includes(item.uuid) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ws-user-data',{attrs:{"item":item}})]}}],null,false,2078938905)}):_vm._e(),(_vm.managersSelectFiltered.length === 0)?_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"size":"80","color":_vm.wsACCENT}},[_vm._v(" mdi-account-tie ")])],1),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.no_managers')))]),_c('h4',{staticClass:"text-center font-weight-regular"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.no_managers_text')))])])]):_vm._e()],1):_vm._e(),(_vm.navigation === 'choose_students')?_c('div',{staticClass:"fill-height"},[(_vm.clientsSelectFiltered.length > 0 && _vm.studentStorageEnabled)?_c('ws-data-table',{attrs:{"items":_vm.clientsSelectFiltered,"headers":_vm.managersHeaders,"search":_vm.search,"row-action":function ($event) { return _vm.selectUser($event.uuid, true); },"footer-portal":"storage_file_access_footer"},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"min-width":"24","width":"24","height":"24","color":_vm.wsACCENT,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectUser(item.uuid)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selectedUsers.includes(item.uuid) ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ws-user-data',{attrs:{"item":item}})]}}],null,false,2078938905)}):_vm._e(),(!_vm.studentStorageEnabled)?_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"size":"80","color":_vm.wsACCENT}},[_vm._v(" mdi-account-school-outline ")])],1),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.students.title')))]),_c('h4',{staticClass:"text-center font-weight-regular px-4 mt-2"},[_vm._v(_vm._s(_vm.$t('file_browser.share_file.students.tooltip')))]),_c('div',{staticClass:"d-flex justify-center"},[_c('ws-button',{staticClass:"mt-4",attrs:{"to":_vm.businessDashLink('settings/storage'),"label":"ToSettings"}})],1)])]):_vm._e()],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('portal-target',{attrs:{"name":"storage_file_access_footer"}}),_c('div',{staticClass:"d-flex justify-space-between pa-6"},[_c('div',[(_vm.navigation==='shared_users' && _vm.items.length > 0)?_c('ws-button',{attrs:{"label":"file_browser.unshare","disabled":_vm.selectedUsers.length === 0,"outlined":""},on:{"click":function($event){return _vm.shareFile(true)}}}):_vm._e()],1),_c('div',[((_vm.navigation==='choose_managers' && _vm.managersSelectFiltered.length > 0) || (_vm.navigation==='choose_students' && _vm.clientsSelectFiltered.length > 0))?_c('ws-button',{attrs:{"label":"file_browser.share","disabled":_vm.selectedUsers.length === 0},on:{"click":_vm.shareFile}}):_vm._e()],1)])]},proxy:true}]),model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}})}
var staticRenderFns = []

export { render, staticRenderFns }